import React from 'react';
import { Helmet } from 'react-helmet';

import HERO from "./heroImage.jpg"

const Seo = props => {
  return (
    <Helmet>
      <html lang="en" />
      <title>That Startup Book | Michal Malewicz</title>

      <meta name="keywords" content="Startup, Own business,Book, Startup book, UX, UI, Interface, user experience, Michal Malewicz" />
      <meta property="og:locale" content="en_EN" />
      <meta property="og:type" content={'website'} />
      <meta property="og:site_name" content="thatstartupbook.com" />
      <meta property="og:image" content={"https://thatstartupbook.com/" + HERO} />
      <meta property="og:image:secure_url" content={"https://thatstartupbook.com/" + HERO} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:title" content="That Startup Book by Michal Malewicz" />
      <meta property="og:description" content={"The practical, no-bullshit guide to building a successful digital business"} />
      <meta property="og:url" content={"https://thatstartupbook.com"} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={"The practical, no-bullshit guide to building a successful digital business"} />
      <meta name="twitter:title" content="That Startup Book by Michal Malewicz" />
      <meta name="twitter:image" content={"https://thatstartupbook.com/" + HERO} />
      <meta name="twitter:image:alt" content="That Startup Book by Michal Malewicz" />
      <meta name="twitter:creator" content="@michalmalewicz" />
      <meta name="twitter:url" content={"https://thatstartupbook.com"} />

      <script async defer data-domain="thatstartupbook.com" src="https://plausible.io/js/plausible.js"></script>
    </Helmet>
  );
};

export default Seo;